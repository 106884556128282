import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { Row, Container, Col } from 'react-bootstrap';

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import GenerateLink from "../components/common/site/generate-link"


const Sitemap = () => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menusHeaders(sort: "Sort:asc") {
        id
        Label
        Link {
          id
        }
        Sort
        Submenu_Search_Title
        In_Submenu_Show_Search
        Add_Sub_Menus {
          Title
          Add_here {
            Submenu_Label
            Submenu_Link {
              id
            }
          }
        }
      }
    }
  }
`);

  const menus = data.glstrapi.menusHeaders;
  return (
    <>
      <Header />
      <div className="wrapper collection-landing">
        <Container>
          <Row>
            <Col lg={12} className="sitemap-page">
              <h1>Sitemap</h1>
              <ul className="sitemap-main-list">
                {menus.map((node, i) => {
                  var main_label = node.Label
                  return <React.Fragment key={i}>
                    {node.Add_Sub_Menus.length === 0 &&
                      <li className="li-main">
                        {node.Link ?
                          <GenerateLink link={node.Link} class="title" label={node.Label}>{node.Label}</GenerateLink> :
                          <>{node.Label}</>
                        }
                      </li>
                    }

                    {node.Add_Sub_Menus.length !== 0 &&
                      <li className="li-main">
                        {node.Link ?
                          <GenerateLink link={node.Link} class="title" label={node.Label}>{node.Label}</GenerateLink> :
                          <>{node.Label}</>
                        }
                        <ul className="sub-menu sitemap-sub-menu-list">
                          {node.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                            return <React.Fragment>
                              {Add_Sub_Menus.Add_here.map((menus, i) => (
                                <React.Fragment>
                                  {menus.Submenu_Label != "Full Market Launch" &&
                                    <li key={i} className='sub-li-list'>
                                      <GenerateLink link={menus.Submenu_Link}>{menus.Submenu_Label}</GenerateLink>
                                    </li>
                                  }
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          })}
                        </ul>
                      </li>
                    }
                  </React.Fragment>
                })}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default Sitemap
